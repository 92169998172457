import React, { useEffect } from 'react';
import { navigate } from "gatsby";

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return 'en';
  }

  const lang = navigator && navigator.language && navigator.language.split('-')[0];
  if (!lang) return 'en';

  switch (lang) {
    case 'ja':
      return 'ja';
    case 'ko':
      return 'ko';
    default:
      return 'en';
  }
};

const QuestionPage = () => {
  useEffect(() => {
    const urlLang = getRedirectLanguage();
    navigate(`/${urlLang}/question`);
  }, []);

  return null;
}

export default QuestionPage;
